@font-face {
  font-family: 'Aleo';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/Aleo-Light-webfont.976f09b1.eot);
  src: url(/static/media/Aleo-Light-webfont.976f09b1.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Aleo-Light-webfont.a16a8080.woff) format('woff'),
    url(/static/media/Aleo-Light-webfont.21375a2e.ttf) format('truetype'),
    url(/static/media/Aleo-Light-webfont.d8bdd013.svg#aleolight) format('svg');
}

@font-face {
  font-family: 'Aleo';
  font-style: italic;
  font-weight: 100;
  src: url(/static/media/Aleo-LightItalic-webfont.6520b7a4.eot);
  src: url(/static/media/Aleo-LightItalic-webfont.6520b7a4.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Aleo-LightItalic-webfont.87b84d6d.woff) format('woff'),
    url(/static/media/Aleo-LightItalic-webfont.1aa2faf3.ttf) format('truetype'),
    url(/static/media/Aleo-LightItalic-webfont.496b9c9b.svg#aleolightitalic) format('svg');
}

@font-face {
  font-family: 'Aleo';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Aleo-Regular-webfont.80b70d41.eot);
  src: url(/static/media/Aleo-Regular-webfont.80b70d41.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Aleo-Regular-webfont.426710ad.woff) format('woff'),
    url(/static/media/Aleo-Regular-webfont.825ce72c.ttf) format('truetype'),
    url(/static/media/Aleo-Regular-webfont.24861539.svg#aleoregular) format('svg');
}

@font-face {
  font-family: 'Aleo';
  font-style: italic;
  font-weight: 400;
  src: url(/static/media/Aleo-Italic-webfont.fcf3ee3d.eot);
  src: url(/static/media/Aleo-Italic-webfont.fcf3ee3d.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Aleo-Italic-webfont.c23a7a26.woff) format('woff'),
    url(/static/media/Aleo-Italic-webfont.33291044.ttf) format('truetype'),
    url(/static/media/Aleo-Italic-webfont.cb6e086c.svg#aleoitalic) format('svg');
}

@font-face {
  font-family: 'Aleo';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/Aleo-Bold-webfont.87453432.eot);
  src: url(/static/media/Aleo-Bold-webfont.87453432.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Aleo-Bold-webfont.54f63789.woff) format('woff'),
    url(/static/media/Aleo-Bold-webfont.e7db0a55.ttf) format('truetype'),
    url(/static/media/Aleo-Bold-webfont.29b14190.svg#aleobold) format('svg');
}

@font-face {
  font-family: 'Aleo';
  font-style: italic;
  font-weight: 700;
  src: url(/static/media/Aleo-BoldItalic-webfont.fe19314e.eot);
  src: url(/static/media/Aleo-BoldItalic-webfont.fe19314e.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Aleo-BoldItalic-webfont.36a34e33.woff) format('woff'),
    url(/static/media/Aleo-BoldItalic-webfont.648444f0.ttf) format('truetype'),
    url(/static/media/Aleo-BoldItalic-webfont.42e68820.svg#aleobolditalic) format('svg');
}
