/* Webfont: LatoLatin-Light */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/LatoLatin-Light.54dc25dc.eot); /* IE9 Compat Modes */
  src: url(/static/media/LatoLatin-Light.54dc25dc.eot?#iefix) format('embedded-opentype'),
    /* IE6-IE8 */ url(/static/media/LatoLatin-Light.c425d3f3.woff2) format('woff2'),
    /* Modern Browsers */ url(/static/media/LatoLatin-Light.4725b48f.woff) format('woff'),
    /* Modern Browsers */ url(/static/media/LatoLatin-Light.f7b5d804.ttf) format('truetype');
  text-rendering: optimizeLegibility;
}

/* Webfont: LatoLatin-LightItalic */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: url(/static/media/LatoLatin-LightItalic.aad33fba.eot); /* IE9 Compat Modes */
  src: url(/static/media/LatoLatin-LightItalic.aad33fba.eot?#iefix) format('embedded-opentype'),
    /* IE6-IE8 */ url(/static/media/LatoLatin-LightItalic.80343ed6.woff2) format('woff2'),
    /* Modern Browsers */ url(/static/media/LatoLatin-LightItalic.0e158367.woff) format('woff'),
    /* Modern Browsers */ url(/static/media/LatoLatin-LightItalic.74baf005.ttf) format('truetype');
  text-rendering: optimizeLegibility;
}

/* Webfont: LatoLatin-Regular */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LatoLatin-Regular.6cfad588.eot); /* IE9 Compat Modes */
  src: url(/static/media/LatoLatin-Regular.6cfad588.eot?#iefix) format('embedded-opentype'),
    /* IE6-IE8 */ url(/static/media/LatoLatin-Regular.3cd36578.woff2) format('woff2'),
    /* Modern Browsers */ url(/static/media/LatoLatin-Regular.586ec8dc.woff) format('woff'),
    /* Modern Browsers */ url(/static/media/LatoLatin-Regular.c305a828.ttf) format('truetype');
  text-rendering: optimizeLegibility;
}

/* Webfont: LatoLatin-Italic */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url(/static/media/LatoLatin-Italic.6df64ed5.eot); /* IE9 Compat Modes */
  src: url(/static/media/LatoLatin-Italic.6df64ed5.eot?#iefix) format('embedded-opentype'),
    /* IE6-IE8 */ url(/static/media/LatoLatin-Italic.5e75c678.woff2) format('woff2'),
    /* Modern Browsers */ url(/static/media/LatoLatin-Italic.6b654922.woff) format('woff'),
    /* Modern Browsers */ url(/static/media/LatoLatin-Italic.d9129929.ttf) format('truetype');
  text-rendering: optimizeLegibility;
}

/* Webfont: LatoLatin-Bold */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LatoLatin-Bold.86f41d54.eot); /* IE9 Compat Modes */
  src: url(/static/media/LatoLatin-Bold.86f41d54.eot?#iefix) format('embedded-opentype'),
    /* IE6-IE8 */ url(/static/media/LatoLatin-Bold.3ff66ada.woff2) format('woff2'),
    /* Modern Browsers */ url(/static/media/LatoLatin-Bold.f323edee.woff) format('woff'),
    /* Modern Browsers */ url(/static/media/LatoLatin-Bold.20ee9bd1.ttf) format('truetype');
  text-rendering: optimizeLegibility;
}

/* Webfont: LatoLatin-BoldItalic */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: url(/static/media/LatoLatin-BoldItalic.328250e7.eot); /* IE9 Compat Modes */
  src: url(/static/media/LatoLatin-BoldItalic.328250e7.eot?#iefix) format('embedded-opentype'),
    /* IE6-IE8 */ url(/static/media/LatoLatin-BoldItalic.e3efadc7.woff2) format('woff2'),
    /* Modern Browsers */ url(/static/media/LatoLatin-BoldItalic.2538f760.woff) format('woff'),
    /* Modern Browsers */ url(/static/media/LatoLatin-BoldItalic.e22cd524.ttf) format('truetype');
  text-rendering: optimizeLegibility;
}
