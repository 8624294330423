#main-login-page.EmailSignUpPage {
  border: 1px solid #dadce0;
}
#main-login-page.EmailSignUpPage h1,
#main-login-page.EmailSignUpPage h3 {
  color: black;
  font-family: Lato;
  font-size: 2rem;
  font-weight: bold;
}
#main-login-page.EmailSignUpPage p {
  color: black;
  font-family: Lato;
  font-size: 1.25rem;
}
#main-login-page.EmailSignUpPage .email {
  font-weight: bold;
}
#main-login-page.EmailSignUpPage .EmailConfirmContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#main-login-page.EmailSignUpPage .confirm-email-form {
  width: 100%;
  padding-top: 2rem;
}
#main-login-page.EmailSignUpPage .confirm-email-form button {
  padding: 1.25rem;
  border: 1px solid #dadce0;
  background-color: transparent;
  font-size: 1.1rem;
}
#main-login-page.EmailSignUpPage .confirm-email-form button:hover {
  background-color: #002957;
  color: white;
}
