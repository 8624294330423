.identification-method-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.identification-method-cards .method-card {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: stretch;
  border-width: 0.2em;
  border-style: solid;
  border-color: black;
  margin: 1em;
  border-radius: 0.5em;
}
.identification-method-cards .method-card button.card-button {
  min-width: 15em;
  height: 10em;
  flex-grow: 1;
  padding: 1em;
  font-size: 16px;
  text-align: center;
}
